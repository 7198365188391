
































































import { defineComponent, ref, reactive, computed, onMounted, watch } from '@vue/composition-api';
import FilterCondition from '@/components/users/setting/filter/content/FilterCondition.vue';
import FilterValue from '@/components/users/setting/filter/content/FilterValue.vue';
import FilterContent from '@/components/users/setting/filter/content/Filter.vue';
import usersApis from '@/apis/users.apis';
import axios from 'axios';
import { cloneDeep } from 'lodash-es';
import { searchTree, getNextStepForCondition } from '@/components/users/usergrp-util';

export default defineComponent({
    name: 'GoalEventVariant',
    props: ['eventName', 'valueOptions', 'propertyValueOptions', 'sid', 'timeZone', 'filter', 'filterLen', 'index'],
    setup(props, context){
        const { root, emit } = context;
        const state: any = reactive({
            filterItem: props.filter, //过滤项
            filterCondition: null, //过滤条件
            filterValue: null //过滤值
        });

        let visibleStates = ref<{
            filterItem?: boolean | undefined;
            filterCondition?: boolean | undefined;
            filterValue?: boolean | undefined;
        }>({
            // filterItem: true,
            // filterCondition: false,
            // filterValue: false
        });
        const options: any = reactive({
            filterItem: props.valueOptions,
            filterCondition: null,
            filterValue: null
        });

        //接口请求状态
        const fetchStates = reactive({
            filterValue: {
                loading: false,
                reqId: 'fetchValueOptions'
            }
        });
        const currFilter = ref(props.filter || {});

        // computed
        const filterValueType = computed(() => {
            const { filterItem, filterCondition } = state;
            return (filterItem && filterItem.cType) || (filterCondition && filterCondition.cType); //有指定组件类型则优先使用
        });
        // watch
        watch(
            () => props.filter,
            val => {
                val && (currFilter.value = val);
                init();
            }
        );
        // onMounted
        onMounted(() => {
            init();
        });
        // onUnmounted

        // function
        function init() {
            /**
             * where里的结构
             * {
             *    key: 'xxx',
             *    condition:'xxxx',
             *    type:'xx',  // 数据类型 STRING NUMBER TIME
             *    value:['dd','aa']
             *    }
             */
            // debugger;
            const { key, type, condition, value } = currFilter.value;
            setState('filterItem', key, 'key');
            setFilterConditionOptions();
            setState('filterCondition', condition || state.filterItem?.defaultCondition);
            setFilterValueOptions();
            updateStates('filterValue', value);
            updateVisibleStates();
        }

        function handleChange(type: string, value: any) {
            // console.log('handleChange is triggered', type, value);
            let needUpdate = true;
            switch (type) {
                case 'filterItem':
                    const { defaultCondition } = value;
                    updateStates('filterItem', value);
                    setFilterConditionOptions();
                    setState('filterCondition', defaultCondition);
                    setFilterValueOptions();
                    updateStates('filterValue');
                    updateVisibleStates();
                    // 如果没有Condition，则说明切换item即为新增完成
                    needUpdate = (options.filterCondition || []).length === 0;
                    break;
                case 'filterCondition':
                case 'filterValue':
                    updateStates(type, value);
                    updateVisibleStates();
                    break;
                default:
                    updateStates(type, value);
                    break;
            }
            _updateFilter(type, needUpdate);
        }

        /**
         * 获取过滤器条件选项列表
         */
        function setFilterConditionOptions() {
            const { type } = state.filterItem || {};
            const optionsVar = type ? getNextStepForCondition(type?.toLocaleLowerCase(), true) : [];
            updateOptions('filterCondition', optionsVar);
        }

        function fetchDictData(params) {
                    // const { sid, timeZone } = this.profileInfo;
                    const { funName, propertyKey, subKey } = params;
                    return usersApis.getDictData(null, {
                        where: {
                            sid: props.sid,
                            funName,
                            propertyKey,
                            subKey,
                            timeZone: props.timeZone,
                            limit: 100
                        }
                    });
                }

        /**
         * 设置过滤器值选项列表
         */
        async function setFilterValueOptions() {
            const { key, valueNeedQuery } = state.filterItem || {};
            // if (props.propertyValueOptions && props.propertyValueOptions?.[key]) {
            //     updateOptions('filterValue', props.propertyValueOptions?.[key]);
            //     return;
            // }
            fetchStates.filterValue.loading && _cancelFeatch(fetchStates.filterValue.reqId);
            fetchStates.filterValue.loading = true;
            if (valueNeedQuery) {
                await fetchDictData(
                    {
                        funName: 'eventPropertyValue',
                        propertyKey: props.eventName,
                        subKey: key
                    }
                ).then( data => {
                        const results = data?.map((item) => {
                            return {
                                code: item.key,
                                name: item.key
                            }
                        }) || [];
                        fetchStates.filterValue.loading = false;
                        updateOptions('filterValue', results);
                        key && emit('updateItems', key, results);
                    });
            }
        }

        function updateOptions(key: string, value = []) {
            if (Object.prototype.hasOwnProperty.call(options, key)) {
                options[key] = value;
            }
        }

        function updateStates(key: string, value = null) {
            if (Object.prototype.hasOwnProperty.call(state, key)) {
                state[key] = value;
            }
        }

        /**
         * 更新组件显示状态
         */
        function updateVisibleStates() {
            const { filterItem, filterValue, filterCondition } = state;
            const { key, type } = filterItem || {};
            const hasFilterValue = Boolean(filterCondition) ? Boolean(filterValue) : true;
            visibleStates.value = {};

            //只保留会展示的组件
            Object.keys(state).forEach(stateName => {
                let visible = true;
                let hasComponent = true;
                switch (stateName) {
                    case 'filterCondition':
                        hasComponent = Boolean(key);
                        visible = Boolean(filterItem);
                        break;
                    case 'filterValue':
                        const { hasValue } = filterCondition || {};
                        hasComponent = hasValue;
                        visible = Boolean(filterItem);
                        break;
                }
                hasComponent && (visibleStates.value[stateName] = visible);
            });
        }

        /**
         * 更新当前过滤器值
         */
        function _updateFilter(stateName: string, needUpdate: boolean) {
            const { filterItem, filterCondition, filterValue } = state;
            const mapper = {
                filterItem: () => {
                    if (!filterItem) return;
                    const { key, type: itemType, defaultCondition } = filterItem;

                    updateFilter('key', key);
                    updateFilter('type', itemType);
                    updateFilter('value', null);
                    updateFilter('condition', defaultCondition);
                },
                filterCondition: () => {
                    const { code, hasValue } = filterCondition || {};
                    updateFilter('condition', code);
                    hasValue || updateFilter('value', []);
                },
                filterValue: () => {
                    const curVal =
                        (filterValue !== undefined &&
                            (Array.isArray(filterValue) ? filterValue : [filterValue])) ||
                        null;
                    updateFilter('value', curVal);
                }
            };
            mapper[stateName] && mapper[stateName]();
            needUpdate && notice();
        }

        function notice() {
            const { type, condition, value } = currFilter.value;
            const { filterCondition, filterValue } = visibleStates.value;
            const hasFilterItem = Boolean(type);
            const visibleFilterCondition = Boolean(filterCondition);
            const hasValue =
                filterValue === undefined ||
                (Array.isArray(value) &&
                    value.length > 0 &&
                    value.some(item => item === 0 || Boolean(item)));
            currFilter.value['error'] = {
                type: !hasFilterItem,
                value: !hasValue
            };
            if (hasFilterItem && hasValue) {
                emit('change', cloneDeep(currFilter.value));
            }
        }

        function updateFilter(key, value) {
            currFilter.value[key] = value;
        }

        /**
         * 设置状态值
         */
        function setState(type: string, code: any, val?: any) {
            const optionsVar = options[type] || [];
            const itemInfo = val
                ? code && searchTree(optionsVar, code, val)
                : code && searchTree(optionsVar, code);
            updateStates(type, itemInfo);
        }

        function handleDelete() {
            emit('delete');
        }

        function createFilter(queryString: string) {
            return option => {
                return option['key'].toLowerCase().indexOf(queryString.toLowerCase()) > -1;
            };
        }

        function querySearchEvent(queryString: string, cb: Function) {
            var options = props.valueOptions;
            var results = queryString ? options.filter(createFilter(queryString)) : options;
            // 调用 callback 返回建议列表的数据
            cb(results);
        }

        function _cancelFeatch(reqId) {
            axios.cancel(reqId);
        }


        return {
            state,
            visibleStates,
            options,
            fetchStates,
            currFilter,
            filterValueType,
            handleChange,
            handleDelete,
            querySearchEvent
        }
    },
    components: {
        FilterCondition,
        FilterValue,
        FilterContent
    }

})

